<template>
    <div class="Box">
        <div>
            <el-select v-model="pageInfo.storageId" placeholder="全部仓库" style="width:120px;margin-right:10px">
                <el-option
                    v-for="item in storageList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.type" placeholder="全部类型" style="width:120px;margin-right:10px">
                <el-option
                    v-for="item in flowingTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select style="width:120px;margin-right:10px" clearable @clear='clearShop' v-model="shopAndMar" placeholder="请选择店铺">
                <el-option-group
                v-for="group in shopList"
                :key="group.id"
                :label="group.name">
                <el-option
                    @click.native="getID(group.id,item.id)"
                    v-for="item in group.marketPlaceTree"
                    :key="item.id"
                    :label="item.name"
                    :value="group.id+'-'+item.id">
                </el-option>
                </el-option-group>
            </el-select>
            <TimeQuantum
            style="margin-right: 10px"
            @selectFinish="sectionSure"
            @sectionFinish="sectionSure"
            ></TimeQuantum>
            <el-input v-model="pageInfo.keyword" placeholder="搜索内容" style="margin-right:10px;width:300px">
                <el-select v-model="pageInfo.searchType" slot="prepend" style="width:100px">
                    <el-option
                    v-for="item in flowingSearchTypeList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
            </el-input>
            <el-button type="primary" @click="find">查询</el-button>
            <TableDialog style="float:right" :tableList='headList' @setTableHead='getHead'></TableDialog>
        </div>
        <div class="content">
            <el-table
                v-loading='loading'
                height="100%"
                @sort-change="sortTable"
                :data="tableData"
                style="width: 100%"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                <el-table-column
                    :sortable="item.sortable?'custom':false"
                    v-for="item in head"
                    :key="item.name"
                    :prop="item.prop"
                    :label="item.name"
                    :min-width="item.width">
                    <template slot-scope="scope">
                        <div v-if="item.name=='品名/SKU'">
                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                        </div>
                        <div v-else>{{scope.row[item.prop]}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[ 20, 30, 40]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum'
import TableDialog from '@/components/tableDialog'
import ShopsAndSites from '@/components/ShopsAndSites'
import { headList } from '@/api/InventoryManage/inventoryFlow.js'
import { pageInit, listPage } from '@/api/InventoryManage/inventoryFlow.js'
export default {
    components:{
        TimeQuantum,
        TableDialog,
        ShopsAndSites
    },
    data(){
        return{
            loading:false,
            shopAndMar:'',
            headList:[],
            head:[],
            flowingSearchTypeList:[],
            flowingTypeList:[],
            shopList:[],
            storageList:[],

            tableData:[],

            pageInfo:{
                current:1,
                pageSize:20,
                storageId:'',
                type:'',
                shopId:'',
                marketPlaceId:'',
                beginTime:'',
                endTime:'',
                searchType:'',
                sort:'',
                sortColumn:'',
                keyword:''
            },
            total:0
        }
    },
    created(){
        this.headList=headList
        this.head=this.headList
        this.getPageInit()
        this.getListPage()
    },
    methods:{
        sortTable(column){
            if(column.order=='ascending'){
                this.pageInfo.sort='asc'
            }else if(column.order=='descending'){
                this.pageInfo.sort='desc'
            }else if(column.order==null){
                this.pageInfo.sort=''
                this.pageInfo.sortColumn=''
                this.getListPage()
                return
            }
            this.pageInfo.sortColumn=column.prop
            this.getListPage()
        },
        getListPage(){
            this.loading=true
            listPage(this.pageInfo).then(res=>{
                this.loading=false
                console.log(res);
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
            })
        },
        sectionSure(val){
            this.pageInfo.beginTime=val.value[0]
            this.pageInfo.endTime=val.value[1]
            console.log(val);
            this.find()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        getPageInit(){
            pageInit().then(res=>{
                console.log(res);
                this.flowingSearchTypeList=res.data.data.flowingSearchTypeList
                this.flowingTypeList=res.data.data.flowingTypeList
                this.shopList=res.data.data.shopList
                this.storageList=res.data.data.storageList
            })
        },
        getID(shop,mar){
            this.pageInfo.shopId=shop
            this.pageInfo.marketPlaceId=mar
            this.find()
        },
        clearShop(){
            this.pageInfo.shopId=''
            this.pageInfo.marketPlaceId=''
            this.find()
        },
        getHead(val){
            this.head=val
        }
    }
}
</script>

<style scoped lang='scss'>
.Box{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .content{
        flex: 1;
        overflow: auto;
        padding-top: 10px;
    }
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
}
::v-deep .el-input-group__append {
    background-color: #fff;
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}

</style>