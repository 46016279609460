export const headList = [
    {name:'操作时间',prop:'createTime',sortable:true,width:150},
    {name:'类型',prop:'typeName'},
    {name:'单据号',prop:'noteNo',width:140},
    {name:'品名/SKU',prop:''},
    {name:'FNSKU/店铺',prop:'',width:130},
    {name:'仓库',prop:'storageName',sortable:true,width:130},
    {name:'可用量',prop:'goodQuantity',sortable:true,width:90},
    {name:'次品量',prop:'badQuantity',sortable:true,width:90},
    {name:'待检量',prop:'toCheckQuantity',sortable:true,width:90},
    {name:'可用锁定量',prop:'goodLockingQuantity',sortable:true,width:120},
    {name:'次品锁定量',prop:'badLockingQuantity',sortable:true,width:120},
    {name:'采购单价',prop:'unitPrice',sortable:true,width:100},
    {name:'单位费用',prop:'unitCost',sortable:true,width:100},
    {name:'单位库存成本',prop:'unitPutCost',sortable:true,width:130},
    {name:'货值',prop:'value',sortable:true,width:90},
    {name:'费用',prop:'cost',sortable:true},
    {name:'库存成本',prop:'total',sortable:true,width:100},
    {name:'操作人',prop:'createByName'},
    {name:'备注',prop:'tips',width:200},
]

import request from '@/utils/request'

export function pageInit(){
    return request({
        url:'/api/warehouseFlowing/pageInit',
        method:'get'
    })
}

export function listPage(params){
    return request({
        url:'/api/warehouseFlowing/listPage',
        method:'get',
        params
    })
}